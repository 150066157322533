
.about-button {
    background-color: transparent;
    color: #fff;
    border: none;
    background-color: #64abea;
    display: block;
    width: 20px;
    padding: 5px 20px 5px 10px;
    margin:5px;
    margin: 2px 0 0 0;
    border-radius: 3px;
    cursor: pointer;
}

.header-button {
    color: #fff;
    border: none;
    background-color: #64abea;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    margin-top: 20px;
    cursor: pointer;
}
.page-button {
    background-color: transparent;
    color: #fff;
    border: none;
    background-color: #52edb2;
    display: block;
    width: 30px;
    padding: 2px;
    border-radius: 2px;
    cursor: pointer;
}

#showPage  {
    width:30px;
  background-color: white;
    border: none;
    display: inline-block;
    text-align: center;
    padding: 1px 6px;
    border-radius: 3px;
}
#totalCount  {
    width:30px;
    border: none;
    display: inline-block;
    text-align: center;
    padding: 1px 6px;
    border-radius: 3px;
}
