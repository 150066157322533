.newNote {
    position: relative;
    color: white;
    background-color: rgb(34, 141, 177);
    text-align: center;
    width: 600px; 
    margin-top:20px;
}

.searchBar {
  position: relative;
  color: white;
  background-color: rgb(34, 141, 177);
  width: 600px;
  margin-top:20px;
  padding: 2px;
}
.pageBar {
  position: relative;
  color: white;
  background-color: rgb(34, 141, 177);
  width: 600px;
  margin-top:1px;
  margin-bottom:20px;
  padding: 2px;
}

.newNote input, textarea {
  width:100%;
  padding: 5px;
}

.newNote button {
    float: right;
    background-color:rgb(160, 14, 14);
    width: 25px;
    color:white;
    border: none;
    cursor: pointer;
}

.oldNote {
    position: relative;
    background-color: rgb(228, 191, 28);
    text-align: center;
    width: 600px;
    margin-top:2px;
}
.oldNote input, textarea {
  width:100%;
  padding: 5px;
}

.oldNote button {
    float: right;
    background-color:rgb(160, 14, 14);
    width: 25px;
    color:white;
    border: none;
    cursor: pointer;
}
