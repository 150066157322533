.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: -1;
  }
  
  .isOpen {
    opacity: 1;
    z-index: 999;
  }
  
  .close-btn {
    background: #7a9be9;
    color:white;
    border: 0px;
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;
  }

  .aboutButton {
    background-color:rgb(14, 160, 50);
    width: 50px;
    color:white;
    border: none;
    cursor: pointer;
}

  